import React from 'react'

function AfternoonCircleIcon() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.93102 16.9895C3.69645 12.453 6.43073 7.78995 11.0382 6.57438M4.93102 16.9895L3.33203 17.4114M4.93102 16.9895C5.23802 18.1177 5.76153 19.1317 6.44575 20M11.0382 6.57438L10.6097 5M11.0382 6.57438C14.4432 5.67604 17.9184 6.91198 19.9987 9.44935M5.79286 10.5383L4.10603 9.57713M18.5665 5.76209L17.5937 7.42491" stroke="#F5C563" stroke-width="1.5" stroke-linecap="round" />
            <path d="M29.1654 19.1667C29.1654 14.1041 25.0613 10 19.9987 10C15.2041 10 11.2692 13.681 10.8661 18.3712C6.63867 18.7735 3.33203 22.3339 3.33203 26.6667C3.33203 31.269 7.06299 35 11.6654 35H29.1654C33.3075 35 36.6654 31.6421 36.6654 27.5C36.6654 23.3579 33.3075 20 29.1654 20L29.128 20.0001C29.1527 19.7256 29.1654 19.4476 29.1654 19.1667Z" fill="#7CDCF6" />
            <path d="M29.128 20.0001C29.1404 20 29.1529 20 29.1654 20C33.3075 20 36.6654 23.3579 36.6654 27.5C36.6654 31.6421 33.3075 35 29.1654 35H11.6654C7.06299 35 3.33203 31.269 3.33203 26.6667C3.33203 22.3339 6.63867 18.7735 10.8661 18.3712M29.128 20.0001C29.1527 19.7256 29.1654 19.4476 29.1654 19.1667C29.1654 14.1041 25.0613 10 19.9987 10C15.2041 10 11.2692 13.681 10.8661 18.3712M29.128 20.0001C28.9576 21.8908 28.2131 23.616 27.0701 25M10.8661 18.3712C11.1291 18.3461 11.3958 18.3333 11.6654 18.3333C13.5417 18.3333 15.2733 18.9535 16.6662 20" stroke="#1C1C1E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default AfternoonCircleIcon