import React from 'react'

function Mood5Icon() {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.8346 17.9984C33.8346 26.7429 26.7458 33.8317 18.0013 33.8317C9.25679 33.8317 2.16797 26.7429 2.16797 17.9984M12.4596 3.16192C14.1844 2.5174 16.0517 2.16504 18.0013 2.16504C19.9509 2.16504 21.8182 2.5174 23.543 3.16192" stroke="#5ECC7B" stroke-width="2.375" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.9027 7.60102L11.7386 9.28666C11.8526 9.5213 12.1565 9.74637 12.413 9.78947L13.9281 10.0433C14.897 10.2061 15.1249 10.9148 14.4268 11.614L13.2489 12.8016C13.0494 13.0027 12.9402 13.3906 13.0019 13.6683L13.3391 15.1385C13.6051 16.3021 12.9924 16.7523 11.9713 16.1441L10.5512 15.2965C10.2947 15.1433 9.87202 15.1433 9.6108 15.2965L8.19071 16.1441C7.17432 16.7523 6.55689 16.2973 6.82286 15.1385L7.16007 13.6683C7.22182 13.3906 7.11258 13.0027 6.9131 12.8016L5.73523 11.614C5.04181 10.9148 5.26503 10.2061 6.23392 10.0433L7.74901 9.78947C8.00073 9.74637 8.3047 9.5213 8.41868 9.28666L9.25459 7.60102C9.71054 6.68638 10.4515 6.68638 10.9027 7.60102Z" stroke="#5ECC7B" stroke-width="2.375" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M26.736 7.60102L27.5719 9.28666C27.6859 9.5213 27.9899 9.74637 28.2463 9.78947L29.7614 10.0433C30.7303 10.2061 30.9583 10.9148 30.2601 11.614L29.0822 12.8016C28.8828 13.0027 28.7735 13.3906 28.8353 13.6683L29.1725 15.1385C29.4384 16.3021 28.8258 16.7523 27.8046 16.1441L26.3845 15.2965C26.1281 15.1433 25.7054 15.1433 25.4441 15.2965L24.024 16.1441C23.0077 16.7523 22.3902 16.2973 22.6562 15.1385L22.9934 13.6683C23.0551 13.3906 22.9459 13.0027 22.7464 12.8016L21.5686 11.614C20.8751 10.9148 21.0984 10.2061 22.0673 10.0433L23.5823 9.78947C23.8341 9.74637 24.138 9.5213 24.252 9.28666L25.0879 7.60102C25.5439 6.68638 26.2848 6.68638 26.736 7.60102Z" stroke="#5ECC7B" stroke-width="2.375" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.668 22.748C13.1122 24.6709 15.4115 25.9147 18.0013 25.9147C20.5911 25.9147 22.8904 24.6709 24.3346 22.748" stroke="#5ECC7B" stroke-width="2.375" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default Mood5Icon