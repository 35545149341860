import React from 'react'

function GrayEveningCircleIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
            <path d="M10.1406 4.84023C10.0584 4.41715 9.90683 4.00034 9.68142 3.60609C9.14253 2.66353 8.28215 2.02526 7.32091 1.75098C7.46945 2.95817 6.90737 4.19374 5.79852 4.84023C4.68966 5.48672 3.349 5.36049 2.38799 4.62699C2.14259 5.60477 2.25978 6.67634 2.79867 7.6189C3.52743 8.89356 4.84412 9.61193 6.20313 9.62598" stroke="#F8F8F8" stroke-width="0.7875" />
            <path d="M15.8281 10.0625C15.8281 7.40463 13.6735 5.25 11.0156 5.25C8.49846 5.25 6.43266 7.18254 6.22099 9.64486C4.00161 9.85606 2.26562 11.7253 2.26562 14C2.26562 16.4162 4.22438 18.375 6.64062 18.375H15.8281C18.0027 18.375 19.7656 16.6121 19.7656 14.4375C19.7656 12.2629 18.0027 10.5 15.8281 10.5L15.8085 10.5C15.8215 10.3559 15.8281 10.21 15.8281 10.0625Z" fill="#F8F8F8" />
            <path d="M15.8085 10.5C15.815 10.5 15.8216 10.5 15.8281 10.5C18.0027 10.5 19.7656 12.2629 19.7656 14.4375C19.7656 16.6121 18.0027 18.375 15.8281 18.375H6.64062C4.22438 18.375 2.26562 16.4162 2.26562 14C2.26562 11.7253 4.00161 9.85606 6.221 9.64486M15.8085 10.5C15.8215 10.3559 15.8281 10.21 15.8281 10.0625C15.8281 7.40463 13.6735 5.25 11.0156 5.25C8.49846 5.25 6.43266 7.18254 6.221 9.64486M15.8085 10.5C15.719 11.4927 15.3282 12.3984 14.7281 13.125M6.221 9.64486C6.3591 9.63172 6.49908 9.625 6.64062 9.625C7.62572 9.625 8.53478 9.95058 9.26605 10.5" stroke="#1C1C1E" stroke-width="0.7875" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default GrayEveningCircleIcon