import React from 'react'

function AlwaysActiveIcon() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.6631 27.0446C25.2425 27.8032 23.6199 28.2332 21.897 28.2332C16.3006 28.2332 11.7639 23.6964 11.7639 18.1C11.7639 16.3771 12.1939 14.7546 12.9524 13.334C7.91506 14.5146 4.16406 19.0359 4.16406 24.4332C4.16406 30.7291 9.2679 35.833 15.5638 35.833C20.9612 35.833 25.4825 32.082 26.6631 27.0446Z" fill="#7E87EF" />
            <path d="M30.1286 16.5995C30.1286 20.2626 27.159 23.2321 23.4959 23.2321C19.8328 23.2321 16.8633 20.2626 16.8633 16.5995C16.8633 12.9363 19.8328 9.9668 23.4959 9.9668C27.159 9.9668 30.1286 12.9363 30.1286 16.5995Z" fill="#F5C563" />
            <path d="M23.4976 3.33398V5.32378M32.8773 25.9796L31.4703 24.5726M15.5242 8.62586L14.1172 7.21886M36.7629 16.5993H34.7731M32.8779 7.21901L31.4709 8.62601" stroke="#F5C563" stroke-width="1.9898" stroke-linecap="round" />
        </svg>
    )
}

export default AlwaysActiveIcon