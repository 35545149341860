import React from 'react'

function NOEQ({ isSelected }) {
    return (
        <svg width="31" height="59" viewBox="0 0 31 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7449 1.0203C17.4753 0.113265 16.2056 -0.0845721 14.936 0.0289948C13.6692 -0.0829064 12.4023 0.116084 11.1356 1.02106C7.35187 3.72437 7.23792 12.1009 7.98055 17.6685L10.8406 15.8378C11.8953 17.8886 13.2964 19.153 14.8341 19.153C16.4223 19.153 17.865 17.8909 18.9306 15.7671L21.9 17.6677C22.6426 12.1001 22.5287 3.7236 18.7449 1.0203ZM26.5261 54.9701C23.9899 56.2691 18.1381 58.8672 15.0202 58.8673H15.02C11.9021 58.8672 6.05033 56.2691 3.51411 54.9701C4.10797 52.2977 6.35971 49.5263 7.41134 48.4747C8.30214 49.217 9.26717 52.4957 9.63834 54.0422C9.46316 51.2394 8.66791 48.4779 7.97446 46.0699C7.19862 43.3758 6.55021 41.1242 7.04018 39.7523C8.22791 39.6039 10.0095 38.6388 11.123 37.8965C3.84816 40.7174 2.77178 34.1848 4.6276 31.4011L3.32853 29.3597L3.88527 31.4011C1.65828 35.4097 4.3183 38.2677 5.92668 39.1956L3.32853 46.6189C2.64806 45.1342 1.21288 41.9051 0.915952 40.8658C0.544787 39.5667 -0.383126 25.4625 0.173622 23.2355C0.61902 21.4539 4.44202 19.5238 6.29785 18.7815C7.93094 18.7815 12.7931 20.0187 15.0201 20.6373C17.2472 20.0187 22.1093 18.7815 23.7424 18.7815C25.5982 19.5238 29.4212 21.4539 29.8666 23.2355C30.4233 25.4625 29.4954 39.5667 29.1243 40.8658C28.8273 41.9051 27.3921 45.1342 26.7117 46.6189L24.1135 39.1956C25.7219 38.2677 28.3819 35.4097 26.1549 31.4011L26.7117 29.3597L25.4126 31.4011C27.2684 34.1848 26.192 40.7174 18.9172 37.8965C20.0307 38.6388 21.8123 39.6039 23 39.7523C23.49 41.1242 22.8416 43.3758 22.0657 46.0699C21.3723 48.4779 20.577 51.2394 20.4019 54.0422C20.773 52.4957 21.7381 49.217 22.6289 48.4747C23.6805 49.5263 25.9322 52.2977 26.5261 54.9701Z" fill={isSelected ? "url(#paint1_linear_3089_6154)" : "#929292"} />
            <defs>
                <linearGradient id="paint0_linear_3089_6154" x1="-0.08634" y1="18.3715" x2="38.8446" y2="21.148" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D6B6F0" />
                    <stop offset="0.76" stop-color="#848CE9" />
                </linearGradient>
                <linearGradient id="paint1_linear_3089_6154" x1="57.1493" y1="1.21524" x2="45.6709" y2="49.6557" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D6B6F0" />
                    <stop offset="0.76" stop-color="#848CE9" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default NOEQ