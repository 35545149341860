import React from 'react'

function GrayAlwaysActiveCircleIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
            <path d="M14.6093 14.6297C13.8523 15.0338 12.9878 15.263 12.0698 15.263C9.08785 15.263 6.67053 12.8456 6.67053 9.86373C6.67053 8.94569 6.89965 8.08117 7.30382 7.32422C4.61975 7.95327 2.62109 10.3624 2.62109 13.2383C2.62109 16.5929 5.34058 19.3124 8.69524 19.3124C11.5711 19.3124 13.9802 17.3138 14.6093 14.6297Z" fill="#F8F8F8" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3739 8.6635C13.3544 8.92303 13.1437 9.13538 12.8842 13.1532C12.6247 9.1337 12.4123 8.92303 8.39453 8.6635C12.414 8.40396 12.6247 8.19161 12.8842 4.17383C13.1437 8.19329 13.3561 8.40396 17.3739 8.6635Z" fill="#F8F8F8" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4341 4.63991C9.60663 4.75792 9.51085 4.85446 9.39284 6.6812C9.27484 4.8537 9.1783 4.75792 7.35156 4.63991C9.17906 4.52191 9.27484 4.42537 9.39284 2.59863C9.51085 4.42613 9.60739 4.52191 11.4341 4.63991Z" fill="#F8F8F8" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7935 11.4651C16.966 11.5831 16.8702 11.6797 16.7522 13.5064C16.6342 11.6789 16.5377 11.5831 14.7109 11.4651C16.5384 11.3471 16.6342 11.2506 16.7522 9.42383C16.8702 11.2513 16.9668 11.3471 18.7935 11.4651Z" fill="#F8F8F8" />
        </svg>
    )
}

export default GrayAlwaysActiveCircleIcon