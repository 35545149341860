import React from 'react'

function GrayAfternoonCircleIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
            <path d="M3.08556 8.92439C2.43742 6.5427 3.87291 4.09461 6.29183 3.45643M3.08556 8.92439L2.24609 9.14587M3.08556 8.92439C3.24674 9.51665 3.52158 10.049 3.88079 10.5049M6.29183 3.45643L6.06689 2.62988M6.29183 3.45643C8.07948 2.98481 9.90396 3.63367 10.9961 4.96579M3.53803 5.53748L2.65244 5.03287M10.2442 3.02998L9.73348 3.90296" stroke="#F8F8F8" stroke-width="0.7875" stroke-linecap="round" />
            <path d="M15.8086 10.0674C15.8086 7.40951 13.654 5.25488 10.9961 5.25488C8.47893 5.25488 6.41313 7.18742 6.20146 9.64975C3.98208 9.86095 2.24609 11.7302 2.24609 14.0049C2.24609 16.4211 4.20485 18.3799 6.62109 18.3799H15.8086C17.9832 18.3799 19.7461 16.617 19.7461 14.4424C19.7461 12.2678 17.9832 10.5049 15.8086 10.5049L15.789 10.5049C15.802 10.3608 15.8086 10.2149 15.8086 10.0674Z" fill="#F8F8F8" />
            <path d="M15.789 10.5049C15.7955 10.5049 15.802 10.5049 15.8086 10.5049C17.9832 10.5049 19.7461 12.2678 19.7461 14.4424C19.7461 16.617 17.9832 18.3799 15.8086 18.3799H6.62109C4.20485 18.3799 2.24609 16.4211 2.24609 14.0049C2.24609 11.7302 3.98208 9.86095 6.20146 9.64975M15.789 10.5049C15.802 10.3608 15.8086 10.2149 15.8086 10.0674C15.8086 7.40951 13.654 5.25488 10.9961 5.25488C8.47893 5.25488 6.41313 7.18742 6.20146 9.64975M15.789 10.5049C15.6995 11.4976 15.3086 12.4033 14.7086 13.1299M6.20146 9.64975C6.33957 9.63661 6.47955 9.62988 6.62109 9.62988C7.60619 9.62988 8.51524 9.95546 9.24652 10.5049" stroke="#1C1C1E" stroke-width="0.7875" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default GrayAfternoonCircleIcon