import React from 'react'

function EveningCircleIcon() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3359 7.21828C16.1794 6.41241 15.8906 5.61848 15.4613 4.86753C14.4348 3.07218 12.796 1.85643 10.965 1.33398C11.248 3.6334 10.1774 5.98687 8.06526 7.21828C5.95315 8.44969 3.39951 8.20925 1.56901 6.8121C1.10158 8.67455 1.3248 10.7156 2.35126 12.511C3.73938 14.9389 6.24736 16.3072 8.83594 16.334" stroke="#F8F8F8" stroke-width="1.5" />
            <path d="M27.1693 17.1676C27.1693 12.105 23.0652 8.00098 18.0026 8.00098C13.208 8.00098 9.27315 11.682 8.86998 16.3721C4.64257 16.7744 1.33594 20.3349 1.33594 24.6676C1.33594 29.27 5.0669 33.001 9.66927 33.001H27.1693C31.3114 33.001 34.6693 29.6431 34.6693 25.501C34.6693 21.3588 31.3114 18.001 27.1693 18.001L27.1319 18.0011C27.1566 17.7266 27.1693 17.4486 27.1693 17.1676Z" fill="#7CDCF6" />
            <path d="M27.1319 18.0011C27.1443 18.001 27.1568 18.001 27.1693 18.001C31.3114 18.001 34.6693 21.3588 34.6693 25.501C34.6693 29.6431 31.3114 33.001 27.1693 33.001H9.66927C5.0669 33.001 1.33594 29.27 1.33594 24.6676C1.33594 20.3349 4.64257 16.7744 8.86998 16.3721M27.1319 18.0011C27.1566 17.7266 27.1693 17.4486 27.1693 17.1676C27.1693 12.105 23.0652 8.00098 18.0026 8.00098C13.208 8.00098 9.27315 11.682 8.86998 16.3721M27.1319 18.0011C26.9615 19.8918 26.217 21.617 25.074 23.001M8.86998 16.3721C9.13303 16.3471 9.39966 16.3343 9.66927 16.3343C11.5456 16.3343 13.2772 16.9545 14.6701 18.001" stroke="#1C1C1E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default EveningCircleIcon