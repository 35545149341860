import React from 'react'

function FULL({ isSelected }) {
    return (
        <svg width="60" height="50" viewBox="0 0 60 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M52.803 2.30808e-06V4.65906H50.4732V20.7976H52.8444V23.4419H50.4732V28.7305H45.8141V23.4419H32.5712L32.5712 20.7976H45.8141V4.65906L6.98829 4.65905V28.7305H2.32924V4.65905H0.000385081L0.000385284 0L52.803 2.30808e-06ZM32.1304 14.6277H29.4861V28.7308H32.1304V14.6277ZM56.3704 14.6277H59.0147V28.7308H56.3704V14.6277ZM29.0454 14.6277H26.4011V28.7308H29.0454V14.6277ZM53.2851 14.6277H55.9294V28.7308H53.2851V14.6277ZM59.0147 35.7194V31.0604L0 31.0603V35.7194H59.0147ZM59.0147 38.0488V42.7079L0 42.7079V38.0488L59.0147 38.0488ZM59.0147 49.6964V45.0373L0 45.0373V49.6964L59.0147 49.6964ZM20.3579 23.4638C20.3579 26.3725 17.9998 28.7305 15.0911 28.7305C12.1824 28.7305 9.82434 26.3725 9.82434 23.4638C9.82434 21.8389 10.5602 20.3859 11.7167 19.4198L10.7729 17.785C9.95096 16.3614 10.9784 14.5819 12.6222 14.5819H17.3061C18.9499 14.5819 19.9773 16.3614 19.1554 17.785L18.2918 19.2808C19.5479 20.2434 20.3579 21.759 20.3579 23.4638ZM16.5567 18.4036L17.4742 16.8144C17.5005 16.7689 17.5038 16.7389 17.5034 16.7179C17.5029 16.6909 17.4949 16.656 17.4742 16.6202C17.4536 16.5844 17.4274 16.56 17.4042 16.5461C17.3862 16.5353 17.3586 16.5232 17.3061 16.5232H12.6222C12.5697 16.5232 12.5421 16.5353 12.5241 16.5461C12.5009 16.56 12.4747 16.5844 12.4541 16.6202C12.4334 16.656 12.4254 16.6909 12.4249 16.7179C12.4245 16.7389 12.4278 16.7689 12.4541 16.8144L13.4105 18.4709C13.9383 18.2933 14.5034 18.197 15.0911 18.197C15.5997 18.197 16.0914 18.2691 16.5567 18.4036Z" fill={isSelected ? "url(#paint1_linear_3089_6154)" : "#929292"} />
            <defs>
                <linearGradient id="paint0_linear_3089_6154" x1="-0.08634" y1="18.3715" x2="38.8446" y2="21.148" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D6B6F0" />
                    <stop offset="0.76" stop-color="#848CE9" />
                </linearGradient>
                <linearGradient id="paint1_linear_3089_6154" x1="57.1493" y1="1.21524" x2="45.6709" y2="49.6557" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D6B6F0" />
                    <stop offset="0.76" stop-color="#848CE9" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default FULL