import React from 'react'

function SHRED({ isSelected }) {
    return (
        <svg width="99" height="77" viewBox="0 0 99 77" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44.6689 16.3486L49.0437 27.631V66.7738L43.9781 76.4443C40.8467 76.8127 33.9238 73.0673 30.8538 71.1485C30.163 67.0808 28.9657 58.9452 29.7025 58.9452C30.4393 58.9452 37.8381 57.8707 41.4454 57.3334C37.6846 56.7962 30.0709 55.5375 29.7025 54.8007C29.242 53.8797 26.7093 42.5973 27.8605 41.9066C28.7815 41.354 36.6868 39.8343 40.5244 39.1436L25.558 38.4528C24.637 36.9792 23.0252 28.6287 22.3345 24.6377C24.1765 22.9799 37.9916 18.4209 44.6689 16.3486Z" fill={isSelected ? "url(#paint0_linear_3089_6154)" : "#929292"} />
            <path d="M24.6369 18.1899L34.0773 13.8151L20.4924 14.9664L18.4201 13.3546H24.8672L22.5647 11.0521H15.1966L0 0L2.07227 17.4991L14.7361 31.3142L29.0117 70.2268L18.4201 26.0184L24.6369 18.1899Z" fill={isSelected ? "url(#paint0_linear_3089_6154)" : "#929292"} />
            <path d="M53.418 16.3486L49.0432 27.631V66.7738L54.1088 76.4443C57.2402 76.8127 64.1631 73.0673 67.2331 71.1485C67.9239 67.0808 69.1212 58.9452 68.3844 58.9452C67.6476 58.9452 60.2488 57.8707 56.6415 57.3334C60.4023 56.7962 68.016 55.5375 68.3844 54.8007C68.8449 53.8797 71.3777 42.5973 70.2264 41.9066C69.3054 41.354 61.4001 39.8343 57.5626 39.1436L72.5289 38.4528C73.4499 36.9792 75.0617 28.6287 75.7524 24.6377C73.9104 22.9799 60.0953 18.4209 53.418 16.3486Z"fill={isSelected ? "url(#paint1_linear_3089_6154)" : "#929292"} />
            <path d="M73.45 18.1899L64.0097 13.8151L77.5945 14.9664L79.6668 13.3546H73.2197L75.5222 11.0521H82.8903L98.0869 0L96.0146 17.4991L83.3508 31.3142L69.0752 70.2268L79.6668 26.0184L73.45 18.1899Z" fill={isSelected ? "url(#paint1_linear_3089_6154)" : "#929292"} />
            <defs>
                <linearGradient id="paint0_linear_3089_6154" x1="-0.08634" y1="18.3715" x2="38.8446" y2="21.148" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D6B6F0" />
                    <stop offset="0.76" stop-color="#848CE9" />
                </linearGradient>
                <linearGradient id="paint1_linear_3089_6154" x1="57.1493" y1="1.21524" x2="45.6709" y2="49.6557" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D6B6F0" />
                    <stop offset="0.76" stop-color="#848CE9" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default SHRED