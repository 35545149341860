import React from 'react'

function GrayNightCircleIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
            <path d="M15.1093 14.1297C14.3523 14.5338 13.4878 14.763 12.5698 14.763C9.58785 14.763 7.17053 12.3456 7.17053 9.36373C7.17053 8.44569 7.39965 7.58117 7.80382 6.82422C5.11975 7.45327 3.12109 9.86236 3.12109 12.7383C3.12109 16.0929 5.84058 18.8124 9.19524 18.8124C12.0711 18.8124 14.4802 16.8138 15.1093 14.1297Z" fill="#F8F8F8" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8739 8.1635C13.8544 8.42303 13.6437 8.63538 13.3842 12.6532C13.1247 8.6337 12.9123 8.42303 8.89453 8.1635C12.914 7.90396 13.1247 7.69161 13.3842 3.67383C13.6437 7.69329 13.8561 7.90396 17.8739 8.1635Z" fill="#F8F8F8" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9341 4.13991C10.1066 4.25792 10.0108 4.35446 9.89284 6.1812C9.77484 4.3537 9.6783 4.25792 7.85156 4.13991C9.67906 4.02191 9.77484 3.92537 9.89284 2.09863C10.0108 3.92613 10.1074 4.02191 11.9341 4.13991Z" fill="#F8F8F8" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2935 10.9651C17.466 11.0831 17.3702 11.1797 17.2522 13.0064C17.1342 11.1789 17.0377 11.0831 15.2109 10.9651C17.0384 10.8471 17.1342 10.7506 17.2522 8.92383C17.3702 10.7513 17.4668 10.8471 19.2935 10.9651Z" fill="#F8F8F8" />
        </svg>
    )
}

export default GrayNightCircleIcon