import React from 'react'

function SIZE({ isSelected }) {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3083_6094)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9783 13.4997C24.3538 13.624 31.4179 14.0661 32.6797 14.8489C32.5554 14.0016 32.1824 12.1136 31.6574 11.3537C28.8944 11.3307 23.1795 11.3215 22.4381 11.4781C21.9638 11.8465 21.0105 12.7629 20.9829 13.4997H20.9783ZM86.8533 32.8224C82.8608 32.4402 73.729 31.9014 69.1239 32.8224C66.7431 34.8947 61.5717 40.0063 59.9139 43.8745L67.9727 44.7955C64.9011 45.3343 58.3942 46.5914 56.9206 47.3283C55.0786 48.2493 44.2568 56.5383 43.566 57.9198C43.0134 59.025 41.3418 66.2089 40.5727 69.6627L39.1912 58.8408C36.967 57.6896 32.3297 55.1568 31.5929 54.2358C30.8561 53.3148 25.1459 38.8089 22.3828 31.6711L25.1459 27.9871L23.7644 25.6846L25.8366 26.8359L27.4484 24.7636L23.7644 21.0796L29.2904 24.3031L29.9812 20.6191L25.1459 17.6258L31.1324 20.1585L31.5929 15.5535C28.9082 14.7845 22.7052 13.3891 19.3896 13.9418C17.1653 18.0863 12.6662 27.2503 12.482 30.7501C12.2518 35.1249 15.9358 71.2744 16.6266 73.1164C17.3173 74.9585 27.6786 86.9315 29.9812 86.9315C31.8232 86.9315 36.8887 84.1685 39.1912 82.787L36.198 86.9315C40.4208 87.3138 49.092 88.0368 50.0131 87.8526C51.1643 87.6223 72.1172 72.8862 73.0382 71.2744C73.775 69.985 75.1888 61.2216 75.8013 56.9988C76.34 59.3796 77.413 64.2748 77.413 64.8274C77.413 65.5181 85.2416 59.3013 85.7021 58.8408C86.1626 58.3803 87.5441 34.8947 86.8533 32.8224Z" fill={isSelected ? "url(#paint0_linear_3083_6094)" : "#929292"} />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M68.001 34.0705C57.0179 34.7797 56.4423 35.36 55.7331 46.3384C55.024 35.3554 54.4437 34.7797 43.4653 34.0705C54.4483 33.3614 55.024 32.7811 55.7331 21.8027C56.4423 32.7857 57.0226 33.3614 68.001 34.0705Z" fill={isSelected ? "url(#paint1_linear_3083_6094)" : "#929292"} />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M69.7283 27.1534C64.2391 27.508 63.9489 27.7981 63.5944 33.2873C63.2398 27.7981 62.9497 27.508 57.4604 27.1534C62.9497 26.7988 63.2398 26.5087 63.5944 21.0195C63.9489 26.5087 64.2391 26.7988 69.7283 27.1534Z" fill={isSelected ? "url(#paint2_linear_3083_6094)" : "#929292"} />
            </g>
            <defs>
                <linearGradient id="paint0_linear_3083_6094" x1="12.3022" y1="38.8478" x2="88.4446" y2="45.9673" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D6B6F0" />
                    <stop offset="0.76" stop-color="#848CE9" />
                </linearGradient>
                <linearGradient id="paint1_linear_3083_6094" x1="43.4096" y1="30.6202" x2="68.4504" y2="33.0234" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D6B6F0" />
                    <stop offset="0.76" stop-color="#848CE9" />
                </linearGradient>
                <linearGradient id="paint2_linear_3083_6094" x1="57.4326" y1="25.4283" x2="69.953" y2="26.6299" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D6B6F0" />
                    <stop offset="0.76" stop-color="#848CE9" />
                </linearGradient>
                <clipPath id="clip0_3083_6094">
                    <rect width="74.5693" height="76.5449" fill="white" transform="translate(12.4736 11.3398)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SIZE