// actionTypes.js
export const FETCH_INITIAL_STATE_REQUEST = 'FETCH_INITIAL_STATE_REQUEST';
export const FETCH_INITIAL_STATE_SUCCESS = 'FETCH_INITIAL_STATE_SUCCESS';
export const UPDATE_COMPLETION_PERCENTAGE = 'UPDATE_COMPLETION_PERCENTAGE';
export const FETCH_INITIAL_STATE_FAILURE = 'FETCH_INITIAL_STATE_FAILURE';
export const TOGGLE_TASK_COMPLETION = 'TOGGLE_TASK_COMPLETION';
export const CHANGE_MOOD_ICON = 'CHANGE_MOOD_ICON';
export const SUBMIT_REFLECTION_FEEDBACK = 'SUBMIT_REFLECTION_FEEDBACK';
// mealinfo changes
export const UPDATE_MEALINFO = 'UPDATE_MEALINFO';
export const UPDATE_MEALURL = 'UPDATE_MEALURL';
