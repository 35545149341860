import React from 'react'

function NightCircleIcon() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.8346 26.9151C26.3928 27.685 24.7461 28.1214 22.9975 28.1214C17.3176 28.1214 12.7132 23.517 12.7132 17.8372C12.7132 16.0885 13.1496 14.4418 13.9195 13C8.80696 14.1982 5 18.7869 5 24.2648C5 30.6547 10.18 35.8346 16.5698 35.8346C22.0477 35.8346 26.6364 32.0277 27.8346 26.9151Z" fill="#7E87EF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M33.1035 15.5517C25.4474 16.0461 25.0461 16.4506 24.5517 24.1035C24.0574 16.4474 23.6529 16.0461 16 15.5517C23.6561 15.0574 24.0574 14.6529 24.5517 7C25.0461 14.6561 25.4506 15.0574 33.1035 15.5517Z" fill="#F8F8F8" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.7763 7.88816C18.2954 8.11292 18.1129 8.29682 17.8882 11.7763C17.6634 8.29536 17.4795 8.11292 14 7.88816C17.481 7.66339 17.6634 7.47949 17.8882 4C18.1129 7.48095 18.2968 7.66339 21.7763 7.88816Z" fill="#F8F8F8" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.7763 20.8882C32.2954 21.1129 32.1129 21.2968 31.8882 24.7763C31.6634 21.2954 31.4795 21.1129 28 20.8882C31.481 20.6634 31.6634 20.4795 31.8882 17C32.1129 20.481 32.2968 20.6634 35.7763 20.8882Z" fill="#F8F8F8" />
        </svg>

    )
}

export default NightCircleIcon